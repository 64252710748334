<template>
  <b-row>
    <b-col sm="12" md="8">
      <my-card :isloading="cardloading">
        <template slot="body">
          <form-validation @submit="submitForm">
            <b-row>
              <b-col sm="12" md="5">
                <form-input :rules="{required: true}" v-model="code" label="Kode"></form-input>
              </b-col>
              <b-col sm="12">
                <form-input :rules="{required: true}" v-model="name" label="Nama"></form-input>
              </b-col>
              <b-col sm="12">
                <form-input v-model="note" label="Keterangan"></form-input>
              </b-col>
              <b-col sm="12" class="mt-3">
                <button-save :isloading="isloading"></button-save>
                <button-back class="ml-1" :to="{name:'regionals'}"></button-back>
              </b-col>
            </b-row>
          </form-validation>
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import FormInput from '@/my-components/FormInput.vue'
import ButtonSave from '@/my-components/ButtonSave.vue'
import ButtonBack from '@/my-components/ButtonBack.vue'
import { mapActions } from 'vuex'
import FormValidation from '@/my-components/FormValidation.vue'

export default {
  components:{
    FormInput,
    ButtonSave,
    ButtonBack,
    FormValidation
  },
  data(){
    return {
      id: this.$route.params.id||null,
      name:'',
      code:'',
      note:'',
      isloading: false,
      cardloading: false
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'regionals/store',
      dispatchShow: 'regionals/show',
      dispatchUpdate: 'regionals/update',
    }),
    async getData(){
      this.cardloading = true
      const data = await this.dispatchShow(this.id)
      this.name = data.name
      this.code = data.code
      this.note = data.note
      this.cardloading = false
    },
    async submitForm(){
      this.isloading = true
      const params = {
        id: this.id,
        name: this.name,
        code: this.code,
        note: this.note
      }
      try {
        if (this.id) await this.dispatchUpdate(params);
        else await this.dispatchStore(params);
        this.toastSuccess("OK","Data berhasil disimpan!")
        this.$router.push({name:'regionals'})
      } catch (error) {
        this.handleError(error)
      }
      this.isloading = false
    },
  },
  mounted(){
    if(this.id) return this.getData();
  }
}
</script>

<style>

</style>